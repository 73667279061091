$primary: rgb(45, 98, 179);
$warning: rgb(255, 119, 7);

@import "/src/assets/theme/base-styles.scss";

$font-weight-light: 200;
$font-weight-regular: 300;
$font-weight-medium: 400;
$font-weight-bold: 800;

@mixin my-font-size($size, $weight) {
    font-size: #{$size}px !important;

    @if $weight == "light" {
        font-weight: $font-weight-light;
    } @else if $weight == "regular" {
        font-weight: $font-weight-regular;
    } @else if $weight == "medium" {
        font-weight: $font-weight-medium;
    } @else if $weight == "bold" {
        font-weight: $font-weight-bold;
    }
}

body {
    font-size: 14px;
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.container-fluid {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.parent {
    position: relative;
    top: 0;
    left: 0;
}

.block-background {
    // background-color: rgba(80, 193, 180, 0.1);
    // border-radius: 10px;
    font-size: 0.75em;
    // padding: 5px;
    // margin-top: 5px;
    // margin-bottom: 5px;
}

.block-info {
    text-align: center !important;
    --bs-text-opacity: 1;
    color: #6c757d !important;
}

.custom-card-height {
    height: 77vh; /* Default for mobile */
}

@media (min-width: 576px) {
    .custom-card-height {
        height: 90vh; /* For desktop */
    }
}

.grid-button {
    max-width: 250px;
}

.btn {
    --bs-btn-border-radius: 1rem;
    --white: #ffffff;
}

.btn-angola {
    @include my-font-size(16, "bold");
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #cd1026;
    --bs-btn-border-color: #cd1026;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #790613;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #790613;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: #ebeff2;
    --bs-btn-disabled-border-color: #6c757d;
    --bs-btn-border-width: 2px;
    min-width: 250px;
    min-height: 40px;
}

.btn-zain {
    --bs-btn-color: #004e5c;
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #1f6470;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1f6470;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
    --bs-btn-border-width: 2px;
}

.btn-zain-next {
    --bs-btn-color: white;
    background-image: linear-gradient(to right, #DC3545, #DC3545) !important;
    background-repeat: repeat-x;
    margin-top: 4px;
    border: none;
}
.btn-zain-next:disabled {
    /*	border-color: #1f6470;*/
    /*	border: 2px solid rgb(31, 100, 112);*/
    /*	border: 2px solid rgba(31, 100, 112, 1);*/
    /* 	-webkit-background-clip: padding-box; for Safari */
    /*    background-clip: padding-box;  for IE9+, Firefox 4+, Opera, Chrome */
    border: none;
    /*    background-image:none !important;*/
    /*    background-color: #b3d5d5;*/
}

.title {
    color: black;
    font-weight: bold;
}

.light-grey {
    background-color: #f9f9f9;
}

.step-title {
    color: black;
    font-weight: bold;
}

.step-info {
    font-size: small;
}

// Usage
.text-small {
    @include my-font-size(10, "regular");
}
.text-light {
    @include my-font-size(14, "light");
}

.text-regular {
    @include my-font-size(14, "regular");
}

.text-medium-regular {
    @include my-font-size(16, "regular");
}

.text-medium {
    @include my-font-size(16, "medium");
}

.text-bold {
    @include my-font-size(22, "bold");
}
